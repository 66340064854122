<template>
  <div>
    <section>
      <h1>{{ this.$route.params.type | titleize}}</h1>
      <div v-if="intro.evolution">
        <text-block
          :data="intro.evolution"
        />
      </div>

      <div class="pt-3 md:pt-5">
        <button
          class="bg-black hover:bg-white text-white hover:text-black transition-colors rounded-full outline-none md:px-3 md:mr-3 md:mb-3 px-2 mr-2 mb-2"
          @click="filterItems(index)"
          v-for="(filter, index) in data"
          :key="index"
        >
          {{ filter.title }}
        </button>
      </div>
    </section>
    <hr/>
    <article 
      v-if="filterItem !== null"
    >
      <h1>{{ filterItem.title }}</h1>
      <component-picker
        :key="filterItem.id"
        :data="filterItem.body"
      />
      <tags
      v-if="filterItem.tags"
      :tags="filterItem.tags"
      />
    </article>
  </div>
</template>

<script>
import ComponentPicker from '@/components/ComponentPicker.vue'

export default {
  name: 'FilterSingle',
  props: ['data'],
  metaInfo() {
    return {
      title: this.title
    }
  },
  components: {
    ComponentPicker,
    Tags: () => import('@/components/Tags.vue'),
    TextBlock: () => import('@/components/blocks/TextBlock.vue')
  },
  data() {
    return {
      filterItem: null,
    }
  },
  mounted() {
    this.filterItem = this.data[this.queryIndex]
  },
  computed: {
    title() {
      return this.$options.filters.titleize(this.$route.params.type)
    },
    queryIndex() {
      let queryIndex
      (this.$route.query.period) 
        ? queryIndex = this.data.findIndex(x => x.slug === this.$route.query.period)
        : queryIndex = 0
      return queryIndex
    },
    intro() {
      return this.$store.getters.introTexts
    }
  },
  methods: {
    filterItems(index) {
      if (this.queryIndex !== index) {
        this.filterItem = this.data[index]
        this.$router.replace({ query: { period: this.filterItem.slug } })
      }
    }
  }
}
</script>
