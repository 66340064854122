<template>
  <div>
    <component
      v-for="(component, index) in data"
      :key="index"
      :is="component.__component"
      :data="component"
    />
  </div>
</template>

<script>
export default {
  name: "ComponentPicker",
  props: ["data"],
  components: {
    "blocks.title": () => import("@/components/blocks/TitleBlock.vue"),
    "blocks.table": () => import("@/components/blocks/TableBlock.vue"),
    "blocks.text": () => import("@/components/blocks/TextBlock.vue"),
    "blocks.carousel": () => import("@/components/blocks/CarouselBlock.vue"),
    "blocks.video": () => import("@/components/blocks/VideoBlock.vue"),
    "blocks.image": () => import("@/components/blocks/ImageBlock.vue"),
    "blocks.video-embed": () =>
      import("@/components/blocks/VideoEmbedBlock.vue"),
    "blocks.buttons": () => import("@/components/blocks/ButtonsBlock.vue"),
    "blocks.sign-up": () => import("@/components/blocks/SignUp.vue"),
  },
};
</script>